var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { ok: _vm.modalOk, hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "history-button",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.state.historyShow = true
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clock-rotate-left"] },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.history")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.exists && _vm.canView("STAFF")
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "auto" },
                          attrs: { size: "sm", variant: "secondary" },
                          on: { click: _vm.viewStaff },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("button.view_staff")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isAccessDenied &&
                  ((_vm.exists && _vm.canEdit()) ||
                    (!_vm.exists && _vm.canAdd()))
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.modalOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("skill.title").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("skill.title").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("skill.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("skill.field.name"),
                                            "data-vv-name": "skill.name",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            trim: "",
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "skill.name"
                                              ),
                                          },
                                          model: {
                                            value: _vm.skill.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.skill, "name", $$v)
                                            },
                                            expression: "skill.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first("skill.name")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["name"] != null
                          ? _vm._l(
                              _vm.customFieldMap["name"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "name" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.skill[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.skill,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "skill[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isIdentifierVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("field.identifier"),
                                      "label-for": "identifier",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "identifier",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("field.identifier"),
                                            "data-vv-name": "skill.identifier",
                                            maxlength: _vm.maxIdentifierLength,
                                            readonly: _vm.isIdentifierReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.skill.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.skill,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression: "skill.identifier",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["identifier"] != null
                          ? _vm._l(
                              _vm.customFieldMap["identifier"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "identifier" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.skill[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.skill,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "skill[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.customFieldMap["default"] != null
                          ? _vm._l(
                              _vm.customFieldMap["default"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.skill[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.skill,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "skill[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isTagVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("TagList", {
                                      attrs: {
                                        holderId: _vm.id,
                                        tags: _vm.tags,
                                        readOnly: _vm.isTagReadOnly,
                                      },
                                      on: { modified: _vm.tagsModified },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["tags"] != null
                          ? _vm._l(
                              _vm.customFieldMap["tags"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "tags" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.skill[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.skill,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "skill[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isColorVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "color-container" },
                                  [
                                    _c("Color", {
                                      attrs: {
                                        disabled: _vm.isColorReadOnly,
                                        update: _vm.updatedColor,
                                      },
                                      model: {
                                        value: _vm.skill.color,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.skill, "color", $$v)
                                        },
                                        expression: "skill.color",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.customFieldMap["color"] != null
                          ? _vm._l(
                              _vm.customFieldMap["color"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "color" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.skill[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.skill,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "skill[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "label",
                  {
                    staticClass: "text-n-line",
                    class: _vm.state.autoScheduleExpanded ? null : "collapsed",
                    attrs: {
                      "aria-expanded": _vm.state.autoScheduleExpanded
                        ? "true"
                        : "false",
                      "aria-controls": "collapse-4",
                    },
                    on: {
                      click: function ($event) {
                        _vm.state.autoScheduleExpanded =
                          !_vm.state.autoScheduleExpanded
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      [
                        _vm._v(
                          _vm._s(_vm.$t("skill.auto_scheduling_title")) + " "
                        ),
                        _vm.state.autoScheduleExpanded
                          ? _c("font-awesome-icon", {
                              staticClass: "collapsable-icon",
                              attrs: { icon: ["far", "chevron-up"] },
                            })
                          : _c("font-awesome-icon", {
                              staticClass: "collapsable-icon",
                              attrs: { icon: ["far", "chevron-down"] },
                            }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "b-collapse",
                  {
                    staticClass: "mt-2",
                    attrs: { id: "collapse-4" },
                    model: {
                      value: _vm.state.autoScheduleExpanded,
                      callback: function ($$v) {
                        _vm.$set(_vm.state, "autoScheduleExpanded", $$v)
                      },
                      expression: "state.autoScheduleExpanded",
                    },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("skill.auto_scheduling_detail.line1"))
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("skill.auto_scheduling_detail.line2"))
                      ),
                    ]),
                    _vm._l(_vm.skill.skillLevels, function (item, index) {
                      return [
                        _c(
                          "b-form-group",
                          {
                            key: index,
                            attrs: {
                              label: _vm.$t("skill.field.skillTypeLevel", [
                                item.kind,
                              ]),
                              "label-for": "sLevel_" + index,
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "sLevel_" + index,
                                    type: "number",
                                    max: 365,
                                    min: 1,
                                    readonly: _vm.isReadOnly,
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.onLevelBlur(index)
                                    },
                                  },
                                  model: {
                                    value: item.data,
                                    callback: function ($$v) {
                                      _vm.$set(item, "data", $$v)
                                    },
                                    expression: "item.data",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    }),
                  ],
                  2
                ),
                _vm.exists &&
                _vm.canView("NOTE") &&
                _vm.canView(_vm.permissionName, ["NOTE"])
                  ? _c(
                      "b-form-group",
                      [
                        _c("NoteList", {
                          attrs: {
                            readOnly:
                              _vm.isReadOnly ||
                              !_vm.canEdit(_vm.permissionName, ["NOTE"]),
                            notes: _vm.notes,
                          },
                          on: {
                            add: _vm.addNote,
                            edit: _vm.editNote,
                            toRemove: _vm.removeNote,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.customFieldMap["notes"] != null
                  ? _c(
                      "b-row",
                      [
                        _vm.customFieldMap["notes"] != null
                          ? _vm._l(
                              _vm.customFieldMap["notes"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "notes" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.skill[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.skill,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "skill[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
        ],
        2
      ),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: "SKILL",
                    customFields: _vm.customFields,
                    links: "NOTE,TAG",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
            _vm.showStaffSelector
              ? _c("StaffSelectorModal", {
                  attrs: {
                    mode: "MANAGE",
                    skill: _vm.skill,
                    show: _vm.showStaffSelector,
                  },
                  on: {
                    "update:show": function ($event) {
                      _vm.showStaffSelector = $event
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }